import Swiper, { Pagination, Navigation, Mousewheel, Autoplay } from "swiper";
import { mq } from "../modules/utils";

import "../sass/app-top.scss";

const g = {
  html: document.getElementsByTagName("html")[0],
  body: document.getElementsByTagName("body")[0],
};

/**
 * インターセクションオブザーバー
 * @param {String} _target - 観測対象の要素のクラス名
 * @param {String} _rootMargin - 観測の領域
 * @param {String} _classname - 観測領域に入ったときに、_targetに追加されるクラス名。
 * @param {Boolean} _cancelObserve - trueにすると一度しか観測しなくなる
 * @returns
 */
const IOFn = (_target, _rootMargin = "0% 0% -20% 0%", _classname = "is-entering", _cancelObserve = true) => {
  const targets = document.querySelectorAll(_target);
  if (!targets.length) return;

  const options = {
    root: null,
    rootMargin: _rootMargin,
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    for (let i = 0; i < entries.length; i += 1) {
      if (entries[i].isIntersecting) {
        entries[i].target.classList.add(_classname);
        if (_cancelObserve) observer.unobserve(entries[i].target);
      } else {
        if (_cancelObserve) return;
        entries[i].target.classList.remove(_classname);
      }
    }
  }, options);

  for (let i = 0; i < targets.length; i += 1) {
    observer.observe(targets[i]);
  }
};

/**
 * 兄弟要素として複製する関数
 * @param {String || DOM} _target - クローンする要素のクラス名かDOM
 * @returns {Array || DOM} - クローンされたDOMの配列か単体のDOM
 */
const cloneDomFn = (_target) => {
  if (typeof _target === "string") {
    const targets = document.querySelectorAll(_target);
    const cloneDomArray = [];
    for (let i = 0; i < targets.length; i += 1) {
      const parent = targets[i].parentNode;
      const clone = targets[i].cloneNode(true);
      parent.append(clone);
      cloneDomArray.push(clone);
    }
    return cloneDomArray;
  }
  if (typeof _target === "object") {
    const parent = _target.parentNode;
    const clone = _target.cloneNode(true);
    parent.append(clone);
    return clone;
  }
  return false;
};

/**
 * 無限スライダー
 * cssアニメーションの補助的な役割で、この関数自身は要素をアニメーションさせない。
 * .js-loopslide[data-dur="X"] - Xで速度調整可能。
 * @param {} - 無し
 * @return {} - 無し
 */
const loopSlideFn = () => {
  const target = document.querySelectorAll(".js-loopslide");
  if (target.length === 0) return;

  target.forEach((_t) => {
    const content = _t.querySelector(".js-loopslide-content");
    const clone = cloneDomFn(content);

    const duration = Number(_t.dataset.dur);
    const delay = (duration / 2) * -1;

    content.style.animationDelay = `${delay}s`;
    [content, clone].forEach((_e) => {
      const element = _e;
      element.style.animationDuration = `${duration}s`;
    });
  });

  IOFn(".js-loopslide", "0%", "running", false);
};

/**
 * メインビジュアルのスライダー SP時
 */
const spSliderFn = () => {
  const slider = document.querySelector(".js-slider");
  if (!slider) return;

  // const test = document.querySelector("#header .header-logo-row");
  // test.innerHTML += "ver.1";

  let scrollValue;
  let ticking = false;
  // if (window.scrollY === 0) {
  //   g.html.style.overflow = "hidden";
  // }

  const option = {
    modules: [Pagination, Mousewheel],
    direction: "vertical",
    speed: 600,
    cssMode: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
    },
  };

  const swiper = new Swiper(slider, option);

  const onScroll = () => {
    scrollValue = window.scrollY;
    if (!ticking) {
      requestAnimationFrame(() => {
        if (scrollValue > 0) {
          // スワイパー領域外
          swiper.disable();
          slider.style.pointerEvents = "none";
        } else {
          // スワイパー領域に入った場合
          slider.style.pointerEvents = "initial";
          swiper.enable();
        }
        ticking = false;
      });
      ticking = true;
    }
  };

  window.addEventListener("scroll", onScroll);
};

/**
 * メインビジュアルのスライダー PC時
 */
const pcSliderFn = () => {
  const slider = document.querySelector(".js-slider");
  if (!slider) return;

  const option = {
    modules: [Navigation, Autoplay],
    speed: 600,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 4000,
    },
  };

  const swiper = new Swiper(slider, option);

  slider.addEventListener("mouseenter", () => {
    swiper.autoplay.stop();
    swiper.autoplay.pause();
  });
  slider.addEventListener("mouseleave", () => {
    swiper.autoplay.resume();
    swiper.autoplay.start();
  });
};

document.addEventListener("DOMContentLoaded", () => {
  loopSlideFn();
  if (mq("sp")) spSliderFn();
  if (mq("pc")) pcSliderFn();
});

window.addEventListener("load", () => {});
